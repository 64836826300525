/* Import Google Font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgb(19, 58, 133);
}
.wrapper {
  padding: 25px;
  border-radius: 10px;
  background: #f8f8f8;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.cards-cust,
.card-cust,
.view {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards-cust {
  height: 400px;
  width: 400px;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cards-cust .card-cust {
  cursor: pointer;
  list-style: none;
  user-select: none;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  height: calc(100% / 4 - 10px);
  width: calc(100% / 4 - 10px);
}
.card-cust.shake {
  animation: shake 0.35s ease-in-out;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-13px);
  }
  40% {
    transform: translateX(13px);
  }
  60% {
    transform: translateX(-8px);
  }
  80% {
    transform: translateX(8px);
  }
}
.card-cust .view {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 7px;
  background: #fff;
  pointer-events: none;
  backface-visibility: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.25s linear;
}
.card-cust .front-view img {
  width: 19px;
}
.card-cust .back-view img {
  max-width: 45px;
}
.card-cust .back-view {
  transform: rotateY(-180deg);
}
.card-cust.flip .back-view {
  transform: rotateY(0);
}
.card-cust.flip .front-view {
  transform: rotateY(180deg);
}

.details {
  height: 30px;
  width: 100%;
  padding-top: 20px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details button {
  cursor: pointer;
  font-size: 14px;
  color: #6563ff;
  border-radius: 4px;
  padding: 4px 11px;
  background: #fff;
  border: 2px solid #6563ff;
  transition: 0.3s ease;
}
.details button:hover {
  color: #fff;
  background: #6563ff;
}

@media screen and (max-width: 700px) {
  .cards-cust {
    height: 350px;
    width: 350px;
  }
  .card-cust .front-view img {
    width: 17px;
  }
  .card-cust .back-view img {
    max-width: 40px;
  }
}

@media screen and (max-width: 530px) {
  .cards-cust {
    height: 300px;
    width: 300px;
  }
  .card-cust .front-view img {
    width: 15px;
  }
  .card-cust .back-view img {
    max-width: 35px;
  }
}

.modal-message-none {
  display: none;
}

.modal-message {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.401);
  z-index: 100;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  width: 300px;
  border-radius: 1em;
  background-color: white;
}

.close-btn {
  position: relative;
  top: -100px;
  left: 280px;
  width: 20px;
  text-align: center;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  background-color: #6563ff;
}

.footer-sec {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.footer-sec .footer-icons a {
  display: inline-block;
  text-decoration: none;
  color: #1f1f1f;
  margin-right: 10px;
}
.footer-sec .footer-icons a i {
  display: block;
  height: 60px;
  width: 60px;
  font-size: 24px;
  color: #1f1f1f;
  line-height: 64px;
  text-align: center;
  border-radius: 40px;
  background: transparent;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.footer-sec .footer-icons a i.la-facebook-f:hover,
.footer-sec .footer-icons a i.la-facebook-f:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #3d558f inset;
  box-shadow: 0 0 15px 30px #3d558f inset;
}
.footer-sec .footer-icons a i.la-twitter:hover,
.footer-sec .footer-icons a i.la-twitter:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #33c9dd inset;
  box-shadow: 0 0 15px 30px #33c9dd inset;
}
.footer-sec .footer-icons a i.la-google:hover,
.footer-sec .footer-icons a i.la-google:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d74937 inset;
  box-shadow: 0 0 15px 30px #d74937 inset;
}
.footer-sec .footer-icons a i.la-linkedin-in:hover,
.footer-sec .footer-icons a i.la-linkedin-in:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #0474bc inset;
  box-shadow: 0 0 15px 30px #0474bc inset;
}

.footer-sec .footer-icons a i.la-instagram:hover,
.footer-sec .footer-icons a i.la-instagram:focus {
  color: #ffffff;
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  /*-webkit-box-shadow: 0 0 15px 30px #fd5949 inset;*/
  /*box-shadow: 0 0 15px 30px #fd5949 inset;*/
  animation: 1s instaRound;
}
.footer-sec .footer-icons a i.la-envelope:hover,
.footer-sec .footer-icons a i.la-envelope:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d45049 inset;
  box-shadow: 0 0 15px 30px #d45049 inset;
}

@-webkit-keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
